import { PARTIAL_AUTHENTICATION_ERROR_CODE } from '@constants/errors'
import { useRouter } from 'next/router'
import { SIGNIN } from '@constants/routes'
import { sessionErrorSelector } from '@features/error/selector'
import { resetAllErrors } from '@features/error/slice'
import { useSelector, useDispatch } from 'react-redux'
import { isPartiallyAuthenticatedSelector } from '@features/context/selector'
import { isAutoLoginSelector } from '@features/user/selector'
import Axios, { Canceler } from 'axios'
import { isRememberMeEnabledSelector } from '@redux/selectors/site'
import { logout } from '@features/user/thunks/logout'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

interface RedirectParamsForPartialAuthError {
  redirectUrl: string
  hasErrorCode: boolean
}

export const useRequireFullAuth = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { errorCode } = useSelector(sessionErrorSelector)
  const isPartiallyAuthenticated = useSelector(isPartiallyAuthenticatedSelector)
  const isAutologin = useSelector(isAutoLoginSelector)
  const isRememberMeEnabled = useSelector(isRememberMeEnabledSelector)
  const { langCode } = useStoreIdentity()

  const redirectToSignIn = (redirectUrl: string): void => {
    router.push({
      pathname: `/${SIGNIN}`,
      query: {
        redirect: redirectUrl,
        isPartiallyAuthenticated: isAutologin ? 0 : 1,
      },
    })
  }

  /**
   * @name Redirects the user to the sign in page if the user is partially authenticated or the session is autologin
   * @param param.redirectUrl - The URL to redirect to after the user is authenticated
   * @param param.noErrorCodeRequired - If true, the user will be redirected to the redirectUrl without checking the error code but forbiddeen urls- example: myaccount forbidden links :
   * @returns
   */

  const handleNotAllowedWithPartialAuth = ({ redirectUrl, hasErrorCode }: RedirectParamsForPartialAuthError): void => {
    if (!redirectUrl) return

    const isPartiallyAuthRedirection = isAutologin ? 0 : 1

    const CancelToken = Axios.CancelToken
    const cancels: Canceler[] = []

    const payloadBase: unknown = {
      redirectTo: `/${langCode}/${SIGNIN}?redirect=${redirectUrl}&isPartiallyAuthenticated=${isPartiallyAuthRedirection}`,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c)
      }),
      ...(isRememberMeEnabled && { rememberMe: false, updateCookies: true }),
    }

    if (
      (isPartiallyAuthenticated && !hasErrorCode && redirectUrl !== '/') ||
      (errorCode === PARTIAL_AUTHENTICATION_ERROR_CODE && isPartiallyAuthenticated) ||
      isAutologin
    ) {
      const payload = payloadBase ?? {}
      dispatch(resetAllErrors())
      dispatch(logout(payload)) // Need to logout the user if the user is partially authenticated due to session issues
      //TODO: Need to check why we need this function to redirect to the sign in page in case of autologin session
      redirectToSignIn(redirectUrl)
    }
  }

  return {
    handleNotAllowedWithPartialAuth,
  }
}
