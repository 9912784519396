import { sessionErrorSelector } from '@features/error/selector'
import { RootReducerState } from '@redux/reducers'
import { defaultStates } from '@redux/reducers/initStates'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { resetErrorSuccessAction } from '../slice'

export const resetSessionError = createAsyncThunk('error/resetSessionError', async (_, { dispatch, getState }) => {
  const state = getState() as RootReducerState
  const sessionErrorObject = await sessionErrorSelector(state)
  if (sessionErrorObject.errorKey || sessionErrorObject.errorCode) {
    //reset session error
    const sessionError = { ...defaultStates.error }
    dispatch(resetErrorSuccessAction(sessionError))
  }
})
