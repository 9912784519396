import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import { userLastUpdatedSelector } from '@features/user/selector'
import { setGuestLoggedIn, setUserFromStorage, setUserLoggedIn } from '@features/user/slice'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import { RootReducerState } from '@redux/reducers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Log from '@services/Log'
import { IPayloadBasePromoCode } from '@typesApp/common'

export type IUpdateUserFromStorageArgs = IPayloadBasePromoCode

export const updateUserFromStorage = createAsyncThunk<any, IUpdateUserFromStorageArgs>(
  'user/updateUserFromStorage',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
      if (currentUser && currentUser.forUserId) return
      const state = getState() as RootReducerState
      const userLastUpdated = userLastUpdatedSelector(state)
      if (currentUser && currentUser.lastUpdated && (!userLastUpdated || userLastUpdated < currentUser.lastUpdated)) {
        dispatch(setUserFromStorage(currentUser))
        if (currentUser.isGuest) {
          dispatch(setGuestLoggedIn(null))
        } else {
          dispatch(setUserLoggedIn(null))
        }
        dispatch(fetchContract())
        dispatch(fetchEntitledOrganization())
        dispatch(fetchUserContext())
      }
      return
    } catch (e) {
      Log.error('error: ' + e)
      return rejectWithValue(e)
    }
  }
)
