import Axios from 'axios'

import { PersonalizationIDResponse, SiteInfo } from '@redux/reducers/reducerStateInterface'
import Log from '@services/Log'
import { localStorageUtil, sessionStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { site, STORES } from '@foundation/constants/site'

const ORGANIC_USER_PROMO = 'OrganicVisit_Campaign'
const ORGANIC_PROMO_REFERRER_CHECKED = 'OrganicPromoReferrer'
const ORGANIC_PROMO_REFERRING_DOMAINS = ['google', 'bing']

async function getPersonalizationID(s: SiteInfo): Promise<PersonalizationIDResponse> {
  try {
    const response = await Axios.get(`${site.transactionContextUrl}/store/${s.storeID}/usercontext/personalizationId`)
    return response
  } catch {
    Log.error('unable to get personalization id', 'useCustomerSegment/index.ts')
    return {}
  }
}

async function callWebActivity(s: SiteInfo, espotName: string, personalizationID: string) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/espot/${espotName}`
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    Log.error('unable to get web activity', 'useCustomerSegment/index.ts')
  })
}

async function getCustomerSegments(s: SiteInfo, personalizationID: string) {
  const url = `${site.transactionContextUrl}/store/${s.storeID}/customersegments`
  return Axios.get(url, {
    headers: { WCPersonalization: personalizationID },
  }).catch(() => {
    Log.error('unable to get customer segments', 'useCustomerSegment/index.ts')
  })
}

async function checkOrganicSearchReferral(s: SiteInfo, personalizationID: string) {
  if (sessionStorageUtil.get(ORGANIC_PROMO_REFERRER_CHECKED)) return

  // document.referrer persists between refreshes,
  // this stops duplicate calls/incorrect behvaiour
  sessionStorageUtil.set(ORGANIC_PROMO_REFERRER_CHECKED, 'true')

  if (!isHomepage(s) && isUserComingFromExternal()) {
    await callWebActivity(s, ORGANIC_USER_PROMO, personalizationID)
  }
}

export async function setCustomerSegment(s: SiteInfo) {
  const windowUrl = window.location.search
  const params = new URLSearchParams(windowUrl)
  const promoParam = params.get('promo')

  const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  let personalizationID = currentUserSession
    ? currentUserSession?.personalizationID
    : localStorageUtil.get(PERSONALIZATION_ID)

  if (!personalizationID) {
    const { data } = await getPersonalizationID(s)
    personalizationID = data?.personalizationID
    localStorageUtil.set(PERSONALIZATION_ID, personalizationID)
  }

  await checkOrganicSearchReferral(s, personalizationID)

  if (promoParam) {
    await callWebActivity(s, promoParam, personalizationID)
  }

  await getCustomerSegments(s, personalizationID)
}

const isUserComingFromExternal = () => {
  if (!document.referrer) return false
  const regexPattern = `\\.(${ORGANIC_PROMO_REFERRING_DOMAINS.join('|')})\\.`
  const regex = new RegExp(regexPattern, 'i')
  const referrer = new URL(document.referrer)
  return regex.test(referrer.hostname)
}

const isHomepage = (s: SiteInfo) => {
  const pathname = window.location.pathname.toLowerCase()
  const locale = s.locale.toLowerCase().replace('_', '-')

  return pathname === STORES[locale].homepagePath
}
