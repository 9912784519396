import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { setUserFromStorage } from '@features/user/slice'
import { WC_PREVIEW_TOKEN } from '@foundation/constants/common'
import { PERSONALIZATION_ID } from '@foundation/constants/user'
import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Log from '@services/Log'
import { IPayloadBasePromoCode } from '@typesApp/common'

export type IInitUserFromStorageArgs = IPayloadBasePromoCode

export const initUserFromStorage = createAsyncThunk<any, IInitUserFromStorageArgs>(
  'user/initUserFromStorage',
  async (payload, { dispatch, rejectWithValue }) => {
    Log.info('INIT STATE FROM STORAGE')
    try {
      let currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
      if (currentUser === null) {
        // if we have both previewtoken and newPreviewSession, the current user is removed in inistates.ts
        // then we should get new personalizationID from preview session
        const previewToken = storageSessionHandler.getPreviewToken()
        if (!previewToken || !previewToken[WC_PREVIEW_TOKEN]) {
          const personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
          if (personalizationID !== null) {
            currentUser = { personalizationID }
          }
        }
      }
      dispatch(setUserFromStorage(currentUser))
      dispatch(fetchUserContext(payload))
      return
    } catch (e) {
      Log.error('error: ' + e)
      return rejectWithValue(e)
    }
  }
)
